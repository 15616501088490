import { MenuItem, Select, TableCell, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function CatalogLine({ product, productsCategories, onUpdate }) {
  const [category, setCategory] = useState(product.categoryId);

  const updateProductCategory = e => {
    setCategory(e.target.value);
    onUpdate({ ...product, categoryId: e.target.value });
  };

  useEffect(() => {
    if (productsCategories && productsCategories.length > 0) {
      if (product.categoryId && productsCategories.find(c => c.id === product.categoryId)) {
        setCategory(product.categoryId);
      } else {
        setCategory("");
      }
    }
  }, [productsCategories]);

  return (
    <TableRow key={product.index}>
      <TableCell component="th" scope="row">
        {product.title}
      </TableCell>
      <TableCell>{product.description}</TableCell>
      <TableCell align="right">{product.price}</TableCell>
      <TableCell align="right">{product.tva}</TableCell>
      {productsCategories && productsCategories.length > 0 ? (
        <TableCell>
          <Select value={category} onChange={updateProductCategory} label="Catégorie" displayEmpty>
            <MenuItem value="">
              <em style={{ color: "gray" }}>Aucune catégorie</em>
            </MenuItem>
            {productsCategories.map(c => (
              <MenuItem value={c.id}>{c.name}</MenuItem>
            ))}
          </Select>
        </TableCell>
      ) : null}
    </TableRow>
  );
}
