import React, { Component, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import { withSnackbar } from "notistack";

import { formatCurrency } from "../../utils/numberFormat";
import InvoiceStatusLabel from "../invoice/InvoiceStatusLabel";
import InvoiceService from "../../services/InvoiceService";

class LinkedInvoicesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      download: "",
      downloadHref: ""
    };
  }

  downloadPDF = invoice => {
    InvoiceService.downloadPDF(invoice)
      .then(res => {
        const downloadHref = window.URL.createObjectURL(res);
        this.setState({ downloadHref, download: `Facture - ${invoice.number}.pdf` });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF de la facture", { variant: "error" });
      });
  };

  simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    this.setState({ downloadHref: "" });
  };

  render() {
    const { onClose, open, orderForm, estimate } = this.props;
    const { download, downloadHref } = this.state;

    const _document = orderForm || estimate;

    return (
      <Dialog
        modal="false"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {_document && _document.invoices.length > 0 ? (
          <Fragment>
            {orderForm && (
              <DialogTitle id="alert-dialog-title">
                {orderForm.invoices.length > 1 ? "Factures liées" : "Facture liée"} au bon {orderForm.number}
              </DialogTitle>
            )}
            {estimate && (
              <DialogTitle id="alert-dialog-title">
                {estimate.invoices.length > 1 ? "Factures liées" : "Facture liée"} au devis {estimate.number}
              </DialogTitle>
            )}

            <DialogContent style={{ minWidth: 300 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Numéro</TableCell>
                    <TableCell>Montant HT</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Télécharger</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_document.invoices.map(invoice => (
                    <TableRow key={invoice.id}>
                      <TableCell>N°{invoice.number}</TableCell>
                      <TableCell>{formatCurrency(invoice.totalPriceWithoutTVA)}</TableCell>
                      <TableCell>
                        <InvoiceStatusLabel invoice={invoice} />
                      </TableCell>
                      <TableCell>
                        <div
                          style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center" }}
                        >
                          <GetApp onClick={() => this.downloadPDF(invoice)} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
          </Fragment>
        ) : (
          <CircularProgress size={40} />
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
            Fermer
          </Button>
        </DialogActions>
        {downloadHref !== "" && (
          <a ref={this.simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
            Download
          </a>
        )}
      </Dialog>
    );
  }
}

export default withSnackbar(LinkedInvoicesDialog);
