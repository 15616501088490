import React from "react";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import Papa from "papaparse";

import { useQuery } from "@tanstack/react-query";
import CompaniesSelect from "../../components/admin/CompaniesSelect";
import UploadFileButton from "../../components/button/UploadFileButton";
import AdminService from "../../services/AdminService";
import CatalogLine from "../../components/admin/import/CatalogLine";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C"
  }
}));

export default function SuperAdminImportCatalogPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [companyId, setCompanyId] = React.useState("");
  const [file, setFile] = React.useState();
  const [products, setProducts] = React.useState([]);

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
  };

  const { data: companyProductsCategories } = useQuery({
    queryKey: ["company_products_categories", { companyId }],
    queryFn: () => AdminService.getCompanyProductsCategories(companyId),
    refetchOnWindowFocus: true,
    retry: 1,
    enabled: !!companyId
  });

  const handleChange = e => {
    setFile({ content: e.file, id: e.id });
    Papa.parse(e.file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        const mappedProducts = results.data.map((product, index) => {
          const category = companyProductsCategories.find(c => c.name === product.categoryName);
          return {
            ...product,
            index,
            categoryId:
              product.categoryId && product.categoryId.trim()
                ? product.categoryId
                : product.categoryName && companyProductsCategories
                ? category
                  ? category.id
                  : ""
                : ""
          };
        });

        setProducts(mappedProducts);
      }
    });
  };

  const handleSubmit = () => {
    AdminService.addProducts(companyId, products)
      .then(() => {
        enqueueSnackbar("Import OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Import KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleLineUpdate = index => product => {
    setProducts(products.map(p => (p.index === index ? { ...p, ...product } : p)));
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Import d&apos;un catalogue</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
          </Paper>
        </Grid>
        {companyId && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <UploadFileButton
                accept=".csv"
                id="file"
                label={file && file.content && file.content.name ? file.content.name : "Choisir un fichier"}
                onChange={handleChange}
              />
            </Paper>
          </Grid>
        )}
        {file && products.length === 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Aucun produit</Typography>
            </Paper>
          </Grid>
        )}
        {file && products.length > 0 && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center" }}>
              <Typography>Produits : {products.length}</Typography>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: "20vw" }}>Dénomination</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Prix HT</TableCell>
                    <TableCell align="right">TVA</TableCell>
                    {companyProductsCategories && companyProductsCategories.length > 0 ? (
                      <TableCell>Catégorie</TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product, index) => (
                    <CatalogLine
                      product={product}
                      productsCategories={companyProductsCategories}
                      key={`product-${index + 1}`}
                      onUpdate={handleLineUpdate(index)}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        )}
        {file && products.length > 0 && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Valider
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
