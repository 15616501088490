import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import CompaniesSelect from "../../components/admin/CompaniesSelect";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  container: { padding: theme.spacing(2) },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": { cursor: "pointer" }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: { flex: 2, color: "#3C3C3C" }
}));

export default function SuperAdminDeleteCatalogPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [companyId, setCompanyId] = React.useState("");
  const queryClient = useQueryClient();

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
  };

  const { data: companyProducts } = useQuery({
    queryKey: ["company_products", { companyId }],
    queryFn: () => AdminService.getCompanyProducts(companyId),
    refetchOnWindowFocus: true,
    retry: 1,
    enabled: !!companyId
  });

  const handleSubmit = () => {
    AdminService.deleteCompanyProducts(companyId)
      .then(() => {
        enqueueSnackbar("Suppression OK", { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["company_products", { companyId }] });
      })
      .catch(e => {
        enqueueSnackbar("Suppression KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Suppression d&apos;un catalogue</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
          </Paper>
        </Grid>
        {companyId && companyProducts && companyProducts.length > 0 ? (
          <Grid item xs={12}>
            <Paper style={{ padding: 16, textAlign: "center", display: "flex", flexDirection: "column", gap: 16 }}>
              <Typography variant="h5">Attention !</Typography>
              <Typography variant="body2">
                Vous êtes sur le point de supprimer tous les produits de la société sélectionnée.
              </Typography>
              <Typography variant="h6">
                {companyProducts.length} produit{companyProducts.length > 1 ? "s" : ""}
              </Typography>
              <Typography variant="caption">Cette action est irréversible.</Typography>
            </Paper>
          </Grid>
        ) : null}

        {companyId ? (
          companyProducts && companyProducts.length > 0 ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                {companyProducts.length > 1
                  ? `Supprimer les ${companyProducts.length} produits`
                  : "Supprimer le produit"}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography>Aucun produit trouvé pour cette société.</Typography>
            </Grid>
          )
        ) : null}
      </Grid>
    </div>
  );
}
