import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";

export default function FranfinanceTransferTypeDialog({ open, onSubmit, onClose }) {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={false} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">Accès à Franfinance</DialogTitle>
      <DialogContent style={{ width: "70vw", maxWidth: 900, display: "flex", flexDirection: "column", gap: 16 }}>
        <div style={{ display: "flex", flexDirection: "row", gap: 24, alignItems: "center" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">Mode tablette/téléphone</Typography>
            <Typography variant="body1">
              En utilisant le mode tablette/téléphone les pièces justificatives seront à prendre en photo à l&apos;aide
              de l&apos;appareil photo de votre tablette/téléphone.
            </Typography>
          </div>

          <Button variant="contained" color="primary" onClick={() => onSubmit("T")}>
            <SmartphoneIcon />
            <span style={{ marginLeft: 8 }}>Continuer</span>
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 24, alignItems: "center" }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">Mode ordinateur (QR code)</Typography>
            <Typography variant="body1">
              En utilisant le mode ordinateur, vous devrez scanner le QR code affiché sur l&apos;écran pour saisir les
              pièces justificatives ou les télécharger.
            </Typography>
          </div>
          <Button variant="contained" color="primary" onClick={() => onSubmit("P")}>
            <DesktopWindowsIcon />
            <span style={{ marginLeft: 8 }}>Continuer</span>
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
