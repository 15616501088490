import React, { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Select,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Paper,
  withStyles,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Tooltip,
  IconButton,
  Checkbox,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { green } from "@material-ui/core/colors";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { TVAS_DROPDOWN } from "../../classes/Product";
import ProductService from "../../services/ProductService";

const DEFAULT_CUSTOM_FIELD = { label: "", required: false, value: "" };

const ProductSchema = () =>
  Yup.object().shape({
    categoryId: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    title: Yup.string()
      .required("Ce champ est requis")
      .max(250, "Le titre ne peut pas dépasser 250 caractères"),
    description: Yup.string().required("Ce champ est requis"),
    tva: Yup.string().required("Ce champ est requis"),
    price: Yup.number().required("Ce champ est requis")
  });

function AddProductForm({ classes, product, customFieldsLimit, handleCancel, handleSubmitForm, isSubmitting }) {
  const { data } = useQuery({
    queryKey: ["company_categories"],
    queryFn: () => ProductService.getProductCategories()
  });

  return (
    <Formik
      enableReinitialize
      initialValues={product}
      validationSchema={ProductSchema}
      onSubmit={values => {
        const { customFields } = values;
        handleSubmitForm({ ...values, customFields: customFields.filter(cf => !!cf.label) });
      }}
    >
      {({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              <Paper className={classes.paper}>
                <Typography variant="h4">Informations sur le produit/prestation</Typography>
                {data && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.categoryId && touched.categoryId}
                    style={{ marginTop: 6 }}
                  >
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                      id="categoryId"
                      name="categoryId"
                      value={values.categoryId}
                      onChange={handleChange}
                      input={<OutlinedInput label="Catégorie" placeholder=" -- Sélectionnez une catégorie -- " />}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em> -- Sélectionnez une catégorie -- </em>
                      </MenuItem>
                      {data.content.length > 0 ? (
                        data.content.map(productCategory => (
                          <MenuItem key={productCategory.id} value={productCategory.id}>
                            {productCategory.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="XXX" disabled>
                          Vous n&apos;avez pas encore renseigné de catégories
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}

                {/* <TextField
                    select
                    id="category"
                    name="categoryId"
                    type="text"
                    placeholder="Catégorie"
                    label="Catégorie"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={values.categoryId}
                    onChange={handleChange}
                    onBlur={handleBlur}

                  >
                    <MenuItem value="">
                      <em>- Sans catégorie -</em>
                    </MenuItem>
                    {data.content.map(productCategory => (
                      <MenuItem key={productCategory.id} value={productCategory.id}>
                        {productCategory.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                <TextField
                  name="reference"
                  placeholder="Référence"
                  label="Référence"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={values.reference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.reference && touched.reference}
                  helperText={errors.reference && touched.reference && errors.reference}
                />
                <TextField
                  required
                  name="title"
                  placeholder="Dénomination"
                  label="Dénomination"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.title && touched.title}
                  helperText={errors.title && touched.title && errors.title}
                />
                <TextField
                  required
                  name="description"
                  placeholder="Description de la prestation"
                  label="Description"
                  margin="normal"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows="3"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.description && touched.description}
                  helperText={errors.description && touched.description && errors.description}
                />
                <TextField
                  select
                  id="tva"
                  name="tva"
                  type="number"
                  required
                  placeholder="TVA"
                  label="TVA"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginRight: 16 }}>
                        %
                      </InputAdornment>
                    )
                  }}
                  value={values.tva}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.tva && touched.tva}
                  helperText={errors.tva && touched.tva && errors.tva}
                >
                  {TVAS_DROPDOWN.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="price"
                  placeholder="0"
                  label="Prix HT"
                  margin="normal"
                  variant="outlined"
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.price && touched.price}
                  helperText={errors.price && touched.price && errors.price}
                />

                {product.customFields.length > 0 && (
                  <Fragment>
                    <Typography variant="h6" className={classes.customFieldsHeader}>
                      Champs personnalisés ({values.customFields.filter(field => field.label !== "").length} /{" "}
                      {customFieldsLimit})
                    </Typography>
                    <Typography variant="subtitle2" className={classes.customFieldsSubHeader}>
                      Un champ est considéré valide dès lors qu&apos;un libellé est renseigné, la valeur par défaut est
                      facultative
                    </Typography>
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <Table aria-labelledby="tableTitle" style={{ minWidth: 575 }} size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Libellé</TableCell>
                            <TableCell>Valeur par défaut</TableCell>
                            <TableCell>Requis</TableCell>
                            <TableCell> </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <FieldArray
                            name="customFields"
                            render={arrayHelpers =>
                              values.customFields.map((customField, index) => {
                                const handleChangeCFLabel = e => {
                                  handleChange(e);
                                  if (
                                    values.customFields.length <= customFieldsLimit - 1 &&
                                    values.customFields.length - 1 === index
                                      ? e.target.value
                                      : values.customFields[values.customFields.length - 1].label
                                  ) {
                                    arrayHelpers.push(DEFAULT_CUSTOM_FIELD);
                                  }
                                };
                                return (
                                  <TableRow key={`customField${index + 1}`}>
                                    <TableCell>
                                      <TextField
                                        name={`customFields.${index}.label`}
                                        placeholder="Libellé"
                                        label={`Libellé #${index + 1}`}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        value={customField.label}
                                        onChange={handleChangeCFLabel}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        name={`customFields.${index}.value`}
                                        placeholder="Valeur"
                                        label={`Valeur #${index + 1}`}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        value={customField.value}
                                        onChange={handleChange}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Checkbox
                                        checked={customField.required}
                                        onChange={() => {
                                          setFieldValue(`customFields.${index}.required`, !customField.required);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {values.customFields.length > 1 && (
                                        <Tooltip title="Supprimer">
                                          <IconButton aria-label="empty" onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            }
                          />
                        </TableBody>
                      </Table>
                    </div>
                  </Fragment>
                )}
                <Grid justify="flex-end" container className={classes.btnWrapper}>
                  <Button variant="outlined" color="primary" className={classes.leftBtn} onClick={handleCancel}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(values)}
                    disabled={isSubmitting || !isValid}
                  >
                    Enregistrer
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  customFieldsHeader: {
    marginTop: theme.spacing(2)
  },
  customFieldsSubHeader: {
    marginBottom: theme.spacing(2),
    opacity: 0.7
  },
  leftBtn: {
    marginRight: theme.spacing(2)
  },
  btnWrapper: {
    paddingTop: theme.spacing(2)
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withStyles(styles)(AddProductForm);
